<template>
  <div>
    <v-card outlined>
      <v-card-title class="title">
        Students' Yearly Attendance Report

        <v-spacer></v-spacer>
        <download-button @action="printAttendanceYearly()"
          v-if="yearlyDataArray.length > 0"
          >Download Yearly Attendance Report</download-button
        >
        <!-- <v-download-column-select
              v-if="
                ($auth.isAdmin() ||
                  $auth.loggedInRole().slug === 'administrator')
              "
              :showPDF="false"
              :downloadColumns="[]"
            >
              DOWNLOAD Yearly Attendance RECORD
            </v-download-column-select> -->
      </v-card-title>
      <v-card-title>
        <v-flex xs3>
          <v-student-search-field
            @onClear="onClear"
            @onSelectStudent="selectStudent"
          ></v-student-search-field>
        </v-flex>
        <v-flex xs3>
          <v-select
            :loading="loadingGrade"
            :disabled="grades.length < 1"
            @change="getSections()"
            :items="[{ value: 'all', text: 'All' },...grades]"
            outlined
            dense
            class="pa-0"
            label="Select grade"
            v-model="grade"
          />
        </v-flex>
        <v-flex xs2>
          <v-select
            :loading="loadingSection"
            :disabled="sections.length < 1"
            :items="sections"
            class="pa-0"
            label="Select section"
            outlined
            dense
            v-model="section"
          />
        </v-flex>
        <v-flex xs3>
          <v-select
            :loading="loadingSection"
            :disabled="!grade"
            :items="years"
            class="pa-0"
            label="Select Year"
            outlined
            dense
            v-model="saal"
          />
        </v-flex>
        <v-flex xs1 sm1>
            <search-button :permission="true" :disabled="saal ? false:true" @action="get()">
            </search-button>
        </v-flex>
        <!-- <v-flex xs1>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="!grade"
                  @click="moreFilterDialogue = true"
                  color="primary"
                  style="margin-top: -20px; float: right"
                  elevation="2"
                  icon
                  ><v-icon>filter_alt</v-icon></v-btn
                >
              </v-flex> -->
      </v-card-title>
    </v-card>
    <!-- <v-container> -->
      <template>
        <v-layout row wrap pa-5>
          <v-flex xs12>
            <v-card elevation="0">
              <div class="scroll-aligner">
                <div class="table-wrapper name_roll" style="width: 100%">
                  <table class="" width="100%">
                    <tr>
                      <th rowspan="2">#</th>
                      <th rowspan="2">Name</th>
                      <th rowspan="2">Grade</th>
                      <th colspan="4" v-for="(month, i) in months" :key="i">
                        {{ month.text }}
                      </th>
                    </tr>
                    <tr>
                      <template v-for="(month,i) in months">
                          <td :key="i + ' - ' + randomString()">
                            Present
                          </td>
                          <td :key="i + ' - ' + randomString()">
                            Absent
                          </td>
                          <td :key="i + ' - ' + randomString()">
                            Late
                          </td>
                          <td :key="i + ' - ' + randomString()">
                            Leave
                          </td>
                      </template>
                    </tr>
                    <tr v-for="(studentRecord,j) in yearlyDataArray" :key="j">
                      <td>{{ j+1 }}</td>
                      <td>
                        {{ studentRecord.name }}
                      </td>
                      <td>
                        {{ studentRecord.grade_name }}({{ studentRecord.section_name }}) 
                      </td>

                      <template v-for="(month,i) in months">
                          <td :key="i + ' - ' + randomString()">
                            <span v-if="yearlyDataObject[studentRecord.enroll_id] && yearlyDataObject[studentRecord.enroll_id]['records'] && yearlyDataObject[studentRecord.enroll_id]['records'][`${saal}-${month.value}`]">
                              {{ yearlyDataObject[studentRecord.enroll_id]['records'][`${saal}-${month.value}`]['present'] || '-' }}
                            </span>
                            <span v-else>
                              -
                            </span>
                          </td>
                          <td :key="i + ' - ' + randomString()">
                            <span v-if="yearlyDataObject[studentRecord.enroll_id] && yearlyDataObject[studentRecord.enroll_id]['records'] && yearlyDataObject[studentRecord.enroll_id]['records'][`${saal}-${month.value}`]">
                              {{ yearlyDataObject[studentRecord.enroll_id]['records'][`${saal}-${month.value}`]['absent'] || '-' }}
                            </span>
                            <span v-else>
                              -
                            </span>
                          </td>
                          <td :key="i + ' - ' + randomString()">
                            <span v-if="yearlyDataObject[studentRecord.enroll_id] && yearlyDataObject[studentRecord.enroll_id]['records'] && yearlyDataObject[studentRecord.enroll_id]['records'][`${saal}-${month.value}`]">
                              {{ yearlyDataObject[studentRecord.enroll_id]['records'][`${saal}-${month.value}`]['late'] || '-' }}
                            </span>
                            <span v-else>
                              -
                            </span>
                          </td>
                          <td :key="i + ' - ' + randomString()">
                            <span v-if="yearlyDataObject[studentRecord.enroll_id] && yearlyDataObject[studentRecord.enroll_id]['records'] && yearlyDataObject[studentRecord.enroll_id]['records'][`${saal}-${month.value}`]">
                              {{ yearlyDataObject[studentRecord.enroll_id]['records'][`${saal}-${month.value}`]['leave'] || '-' }}
                            </span>
                            <span v-else>
                              -
                            </span>
                          </td>
                      </template>

                    </tr>
                  </table>
                  <hr>
                  <!-- <table class="data">
                    <thead class="header">
                      <tr style="border-bottom: 1px solid black">
                        <th>Roll</th>
                        <th style="padding: 26px" width="400px">Name</th>
                      </tr>
                      <tr>
                       <th style="padding: 26px" width="200px" >

                       </th>
                      </tr>
                      <tr>
                        <th>1</th>
                        <th>Hary</th>
                      </tr>
                      <tr>
                        <th>2</th>
                        <th>Gun</th>
                      </tr>
                    </thead>
                    <tbody class="results">
                     <th></th>
                    </tbody>
                  </table> -->
                </div>
                <!-- <div class="table-wrapper calendar_info">
                  <table class="data">
                    <thead class="header">
                      <tr style="border-bottom: 1px solid black">
                        <th
                          v-for="(n, i) in months"
                          :key="i"
                          colspan="4"
                        >
                          {{ n.text }}
                        </th>
                        <th width="200px;">Total Present Day</th>
                        <th>Total Absent Day</th>
                        <th>Total Late Day</th>
                        <th>Total Leave Day</th>
                      </tr>
                      
                      <tr style="border-bottom: 1px solid black">
                        <th
                          v-for="(n, i) in months"
                          :key="i"
                          colspan="4"
                        >
                        <td>
                          <th colspan="1">
                            Present
                          </th>
                          <th colspan="1">
                            Absent
                          </th>
                          <th colspan="1">
                            Leave
                          </th>
                          <th colspan="1">
                            Late
                          </th>
                        </td>
                        </th>
                        <th ></th>
                        <th></th>
                        <th>Total Late Day</th>
                        <th>Total Leave Day</th>
                      </tr>
                      <tr >
                       <th style="padding: 15.5px" width="100px" >
                          present
                       </th>
                       <th style="padding: 15.5px" width="100px" >
                          Absent
                       </th>
                       <th style="padding: 15.5px" width="100px" >
                          Late
                       </th>
                       <th style="padding: 15.5px" width="100px" >
                          Leave
                       </th>
                       <th style="padding: 15.5px" width="100px" >
                          present
                       </th>
                       <th style="padding: 15.5px" width="100px" >
                          Absent
                       </th>
                       <th style="padding: 15.5px" width="100px" >
                          Late
                       </th>
                       <th style="padding: 15.5px" width="100px" >
                          Leave
                       </th>
                      </tr>
                     
                    </thead>
                    <tbody class="results">
                    
                        <tr>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                          
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                          <td>1</td>
                          
                        </tr>
                    </tbody>
                  </table>
                </div> -->
              </div>
            </v-card>
          </v-flex>
        </v-layout>
        <v-dialog v-model="moreFilterDialogue" persistent max-width="350">
            <v-card>
              <v-card-title class="title pa-3 primary white--text">
                <v-icon class="mr-2 white--text">filter_alt</v-icon>
                More Filters
              </v-card-title>

              <v-card-title class="title pa-3">
                <br />
                <v-flex xs12>
                  <v-calendar-field
                    id-val="from_date_11"
                    v-model="from_date"
                    label="From Date"
                  >
                  </v-calendar-field>
                </v-flex>
                <!-- <br> -->
                <v-flex xs12 style="margin-top: 5px;">
                    <v-calendar-field
                    id-val="to_date_11"
                    v-model="to_date"
                    label="To Date"
                  >
                  </v-calendar-field>
                </v-flex>
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  
                  color="warning"
                  outlined
                  small
                  @click="moreFilterDialogue = false,from_date='',to_date=''"
                  >Close</v-btn
                >
                <v-btn color="success"  small @click="get()" :disabled="!grade">
                  Filter Now
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
      </template>
    <!-- </v-container> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";
import {randomString} from "@/library/helpers";


const NepaliDate = require("nepali-date");
const nd = new NepaliDate();
export default {
  mixins: [Mixins],

  data: () => ({
    form: new Form({}, "/api/attendance/yearly"),
    grades: [],
    tab: null,
    grade: "",
    section: "",
    sections: [],
    loadingGrade: false,
    loadingSection: false,
    month: "",
    months: [
      { value: "01", text: "Baisakh" },
      { value: "02", text: "Jestha" },
      { value: "03", text: "Ashad" },
      { value: "04", text: "Shrawan" },
      { value: "05", text: "Bhadra" },
      { value: "06", text: "Asoj" },
      { value: "07", text: "Kartik" },
      { value: "08", text: "Mangsir" },
      { value: "09", text: "Poush" },
      { value: "10", text: "Magh" },
      { value: "11", text: "Falgun" },
      { value: "12", text: "Chaitra" },
    ],
    status:[
      {value:"present" , text:"Present"},
      {value:"absent" , text:"Absent"},
      {value:"leave" , text:"Late"},
      {value:"late" , text:"Late"},
    ],
    days: [],
    years: [{ value: nd.format("YYYY"), text: nd.format("YYYY") }],
    yearlyDataObject:{},
    yearlyDataArray: [],
    saal: "",
    enrollCode: "",
    pagination: {
      rowsPerPage: 100,
      sortBy: "roll",
    },
    printUrl:'',
    moreFilterDialogue:false,
    from_date:'',
    to_date:'',
  }),

  computed: {
    ...mapState(["batch", "batches"]),
  },
  mounted() {
    this.getGrades();
    // this.get();
  },

  methods: {
    randomString: randomString,
    get(params){
        let extraparams ="sectionId="+this.section+"&year="+this.saal+"&enrollCode="+this.enrollCode+"&gradeId="+this.grade+"&from_date="+this.from_date+"&to_date="+this.to_date
        let query = [null, undefined].includes(params)
        ? this.queryString(extraparams)
        : params;
        this.yearlyDataArray = [];
        this.form.get(null, query).then(({ data }) => {
          if(data && data.yearlyRecord){
          
            this.yearlyDataObject = data.yearlyRecord;
            Object.keys(data.yearlyRecord).map((item)=>{
              this.yearlyDataArray.push(data.yearlyRecord[item])
            })

            this.printUrl = data.downloadUrl;
            // console.log(this.yearlyDataObject,this.yearlyDataArray);
          }
        });
    },
    onClear() {
      this.enrollCode = "";
      this.get();
    },
    selectStudent(student) {
      if (student) {
        this.enrollCode = student.enroll_code;
        if (this.grade && this.section && this.month && this.saal) this.get();
      }
    },

    getGrades() {
      this.sections = [];
      this.section = "";
      this.loadingGrade = true;
      this.$rest
        .get(
          "/api/grades?rowsPerPage=25&sortBy=rank&descending=false&slim=true"
        )
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        })
        .finally((err) => {
          this.loadingGrade = false;
        });
    },
    getSections() {
      this.section = "";
      this.sections = [];
      if(this.grade !== 'all' && this.grade ){
        this.loadingSection = true;
        this.$rest
          .get("/api/section?batch=" + this.batch.id + "&grade=" + this.grade)
          .then(({ data }) => {
            this.sections = data.data.map((item) => {
              return { value: item.id, text: item.name };
            });
          })
          .finally(() => {
            this.loadingSection = false;
          });
      }
    },
    printAttendanceYearly() {
      window.open(this.printUrl);
    },
  },
};
</script>
